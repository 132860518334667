/* Header container */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 35px; /* Adjust header height */
  background-color: #000; /* Black background */
  padding: 10px 20px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center; /* Center items vertically */
}

/* Logo container */
.header-logo-container {
  flex-shrink: 0; /* Prevent logo from shrinking */
  margin-right: 20px; /* Space between logo and navigation */
}

/* Logo styling */
.header-logo {
  height: 60px; /* Adjust logo height */
  object-fit: contain;
}

/* Navigation container */
.nav {
  flex-grow: 1; /* Take up remaining space */
}

/* Navigation list */
.nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: flex-start; /* Align links to the left of the navigation */
}

/* Navigation list items */
.nav li {
  margin: 0 20px;
}

/* Navigation links */
.nav-link {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #f5a623; /* Highlight color on hover */
}

