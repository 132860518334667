/* First.css */
.first-container {
  text-align: center;
  margin: 20px;
}
.second-container {
  display: flex;
  flex-direction: column; /* Stack the carousel and text vertically */
  align-items: center;
  margin: 20px;
  text-align: left;
  width: 300px;
  height:500px;

}

/* Container for the slideshow */
.image-container {
  position: relative;
  overflow: hidden;
}

/* Profile image styling for slideshow */
.profile-image {
  transition: transform 1s ease-in-out; /* Adds smooth sliding */
}
.left-aligned {
  text-align: left;
}

/* Slideshow Section */
.banner-section {
  background-color: #000; /* Black background for the banner */
  padding: 20px 0;
  width: 100%;
}

/* Styling for the carousel images */
.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slick-carousel-container {
  max-width: 500px; /* Set the maximum width for the carousel */
  margin: 0 auto; /* Center the carousel */
}

/* Slideshow control buttons */
.slick-prev, .slick-next {
  display: none; /* Hide the arrows if you prefer to use swipe or dots */
}

/* Dots for carousel navigation */
.slick-dots {
  bottom: 10px; /* Position dots at the bottom of the carousel */
  text-align: center;
}

.slick-dots li button:before {
  color: #fff; /* Color of inactive dots */
}

.slick-dots li.slick-active button:before {
  color: #FADDAD; /* Active dot color */
}

/* Styling for image in carousel */
.profile-image {
  width: 100%;
  height: auto;
  max-width: 100%; /* Ensure it adapts to any screen size */
  border-radius: 10px;
  object-fit: cover; /* To ensure the images are cropped correctly */
}

/* Container for the content section */
.content-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  padding: 20px;
}
.content-section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  padding: 20px;
}
.image-container {
  
  flex: 1;

}

.text-container {
  flex: 2;
}
.text-container p {
  text-align: left;
}


/* For text and animated stories */
.animated-stories-section {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 40px 20px;
  margin-top: 30px;
}

.animated-stories-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.animated-stories-section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.migudp-image {
  width: 80%;
  max-width: 400px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.story-description {
  font-size: 1rem;
  max-width: 600px;
  line-height: 1.5;
  margin: 0 auto 20px;
  padding: 0 10px;
}

/* Video Section */
.video-container {
  max-width: 450px;
  margin: 0 auto;
  border: none;
}

.migu-video {
  width: 100%;
  border-radius: 10px;
}

.video-caption {
  margin-top: 10px;
  font-size: 1rem;
  color: #ccc;
}

/* Motivational Shorts Section */
.motivational-shorts-section {
  padding: 40px 20px;
  color: #fff;
  text-align: center;
  transition: background-color 0.5s ease; /* Smooth background transition */
}

.motivational-shorts-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.motivational-video {
  width: 100%;
  border-radius: 10px;
}
.current-animation{
  width: 100%;
  border-radius: 10px;
}
.video-caption {
  margin-top: 10px;
  font-size: 1rem;
  color: #ccc;
}
/* First.css */
body {
  background-color: #000; /* Black background for the entire page */
  color: #fff; /* Optional: White text for contrast */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  font-family: 'Poppins'; /* Replace with your chosen font */

}
/* Styling for highlighted text */
.highlight {
  color: #ff9e02; /* Bright color for emphasis */
  font-weight: 600; /* Semi-bold text */
}

.highlight.bold {
  font-weight: bold; /* Fully bold text for emphasis */
}
.bordered {
  border-bottom: 2px solid #fdf8f8; /* Gray line to separate videos */
  margin-bottom: 20px; /* Space below the border */
  padding-bottom: 10px;
  /* Space inside the container before the border */
}

/* Other styles */
.read-more-btn {
  background-color: #f5a623; /* Button color */
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.read-more-btn2 {
  background-color: #2438b5; /* Button color */
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.read-more-btn:hover {
  background-color: #d48820; /* Hover effect */
}
.mobile-profile {
  width: 300px;
  transition: width 0.2s ease; /* Smooth transition for width */
}

.mobile-profile.shrink {
  width: 100px; /* Target width after animation */
}

.footer-content {
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  bottom: 0;
  width: 100%;
}
.video-js {
  width: 100%;
  height: auto;
  max-height: 500px;
  margin: 0 auto;
  border-radius: 20px;
}
.video-title {
  text-align: center;
  color: white;
  margin-bottom: 10px;
}
.close-btn {
  position: fixed;
  top: 150px; /* Bring it down from the top */
  left: 48%; /* Center it horizontally */
  transform: translateX(-50%); /* Adjust the position to be exactly centered */
  font-size: 50px; /* Increase the size */
  color: rgb(188, 186, 255); /* Set the color */
  cursor: pointer;
  z-index: 1100; /* Ensure it is on top of other content */
}


.close-btn:hover {
  color: #ff9e02; /* Change color on hover */
}
.close-text:hover {
  color: #ff9e02;  /* Change color on hover */
}
.close-text {
  font-size: 30px; /* Same font size as the icon */
  position: fixed;
  top: 150px; /* Align it with the button */
  left: calc(48% + 60px); /* Position it next to the button */
  transform: translateX(0); /* Remove the previous translateX */
  color: rgb(188, 186, 255); /* Set the color */
  cursor: pointer;
  z-index: 1100; /* Ensure it is on top of other content */
}