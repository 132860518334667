/* General Container */
.brand-container {
  display: flex;
  flex-direction: column;
  gap: 50px; /* Increased space between sections */
  padding: 20px;
  margin: 0 auto; /* Centering */
  box-sizing: border-box; /* Ensures padding/margin are accounted for */
}

/* Section Definition */
.brand-definition {
  text-align: center;
  margin-bottom: 30px; /* Additional spacing for the introduction section */
}

.brand-definition h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.brand-definition p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: black;
}

/* Individual Brand Item */
.brand-item {
  display: flex;
  gap: 20px; /* Space between image/video and text */
  align-items: flex-start;
  border-bottom: 1px solid gray; /* Divider */
  padding-bottom: 20px;
  margin-bottom: 30px; /* Additional space between sections */
}

.brand-item:last-child {
  border-bottom: none; /* No border for the last item */
  margin-bottom: 0; /* Ensure no extra spacing after the last section */
}

/* Images */
.brand-image {
  padding:50px;

  height: 300px;
  width: 300px;
  border-radius: 20px;
  object-fit: cover; /* Ensures the image retains its aspect ratio */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Videos */
.brand-video {
  padding:50px;
  height: 400px;
  width: 400px;
  border-radius: 20px;
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .brand-item {
    flex-direction: column; /* Stack items on mobile */
    align-items: center;
  }

  .brand-image, .brand-video {
    width: 100%;
    height: auto; /* Scale down proportionally */
    max-width: 600px; /* Limit max width */
  }
}
